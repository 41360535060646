@import '../../../../scss/theme-bootstrap';

.cart-confirm {
  position: relative;
  width: 100%;
  border: solid 1px $color-black;
  background-color: $color-white;
  color: $color-black;
  z-index: 1;
  top: 22px;
  @include breakpoint($medium-up) {
    top: 0;
    width: 437px;
  }
  .close {
    background: url('/media/images/global/close.svg') no-repeat 0 0 transparent;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    margin: 10px;
    width: 18px;
    height: 18px;
    display: block;
    color: transparent;
    float: $rdirection;
  }
  .shopping-title {
    margin: 15px 25px;
    letter-spacing: 0;
    text-align: $ldirection;
    text-transform: uppercase;
    line-height: 12px;
    @include breakpoint($medium-up) {
      margin: 30px 25px 10px 25px;
    }
  }
  .shopping-title,
  .subtotal,
  .checkout,
  .cart-product-name {
    font-family: $header-font-bold;
    font-size: 12px;
  }
  .cart-product-subhead,
  .cart-product-size,
  .cart-product-qty {
    font-family: $main-font;
    font-size: 12px;
  }
  .has-items-in-bag {
    width: 100%;
    @include breakpoint($medium-up) {
      width: 436px;
    }
  }
  .no-items-in-bag {
    padding: 0 10px;
    height: auto;
    max-height: 320px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: $ldirection;
    @include breakpoint($medium-up) {
      padding: 0 20px 10px 20px;
      width: 436px;
    }
    a.button {
      margin-#{$ldirection}: 5px;
    }
  }
  .cart-products {
    padding: 0 30px;
    border-bottom: 1px solid $color-black;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 210px;
    height: auto;
    width: 100%;
    @include breakpoint($medium-up) {
      width: 436px;
      max-height: 320px;
    }
    .prod {
      padding-bottom: 20px;
      line-height: 1.1em;
      width: 100%;
      @include breakpoint($medium-up) {
        width: 370px;
      }
      a {
        color: $color-black;
      }
      img {
        margin-#{$rdirection}: 2%;
        float: $ldirection;
        width: 54px;
        height: 54px;
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 15px;
        }
      }
      .prod-info {
        display: grid;
        text-align: $ldirection;
        a,
        .cart-product-name {
          display: block;
          font-size: 12px;
          font-family: $main-font;
          letter-spacing: 0;
          text-transform: uppercase;
          text-align: $ldirection;
        }
        .cart-product-name {
          font-family: $header-font-bold;
        }
        .shade_info {
          text-transform: none;
          font-family: $main-font;
          font-size: 12px;
          height: 28px;
          padding-top: 5px;
          .shade_thumb {
            width: 22px;
            height: 22px;
            background: $color-gray;
            border-radius: 50%;
            transition: all 0.2s ease-in-out;
            display: block;
            float: $ldirection;
            margin-#{$rdirection}: 5px;
          }
          span {
            display: inline-block;
            padding-top: 4px;
          }
        }
      }
      .cart-product-price {
        font-family: $header-font-bold;
        font-size: 12px;
      }
      .cart-product-qty {
        padding: 5px 0 8px 0;
      }
    }
    .view_bag {
      padding: 10px;
      text-align: center;
      a {
        color: $color-black;
        border-bottom: solid 1px $color-black;
        font-size: 14px;
      }
    }
  }
  .subtotal {
    padding: 20px 30px;
    font-size: 14px;
    letter-spacing: 0;
    text-transform: uppercase;
    line-height: 12px;
    &-text {
      float: $ldirection;
    }
    &-value {
      float: $rdirection;
    }
  }
  .checkout {
    padding: 10px 30px 30px 30px;
    margin: 0;
    width: 100%;
    line-height: 12px;
    .button {
      @include elc-button--inverted;
      font-family: $header-font-bold;
      background-image: none;
      letter-spacing: 0;
      font-size: 14px;
      width: 100%;
      height: 30px;
      line-height: 15px;
      background-color: $color-black;
      color: $color-white;
      &:visited {
        color: $color-white;
      }
    }
  }
  .promos {
    width: 100%;
    // Default free shipping.
    .free_ship {
      width: 100%;
      height: 55px;
      background: $color-black;
      color: $color-white;
      text-align: center;
      h2 {
        color: $color-white;
        padding-top: 7px;
        letter-spacing: 4px;
        line-height: 20px;
        font-family: $main-font;
        margin-bottom: 0;
        text-align: center;
      }
      p {
        text-transform: none;
        font-family: $main-font;
        font-size: 12px;
      }
    }
  }
}
